import React from "react";
import Link from "next/link";

import styles from "../styles/Button.module.scss";
import { className } from "../utils";

interface Props {
  type?: string | null;
  href?: string | null;
  onClick?: (() => void) | null;
  dark?: boolean;
  dense?: boolean;
  primary?: boolean;
  secondary?: boolean;
  children: React.ReactNode;
}

export default function Button({
  children,
  href = null,
  type = null,
  dark = false,
  dense = false,
  onClick = null,
  primary = false,
  secondary = false
}: Props) {
  const buttonClassName = className(styles, "button", { dark, primary, secondary, dense });

  let attributes: { [key: string]: any } = {};

  if (!!type) {
    attributes["type"] = type;
  }

  if (!!onClick) {
    attributes["onClick"] = onClick;
  }

  if (!!href) {
    return (
      (<Link href={href} {...attributes} className={buttonClassName}>
        {children}
      </Link>)
    );
  }

  return (<button {...attributes} className={buttonClassName}>
    {children}
  </button>)
}
