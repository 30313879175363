import Link from "next/link";

import { formatPrice, formatTime } from "../api/strapi/utils";
import styles from "../styles/VideoList.module.scss";

import { Article } from "../api/strapi/article";
import VideoThumbnail from "./VideoThumbnail";
import Label from "./Label";
// @ts-ignore
import CaretFill from "./icons/CaretFill.svg";
import { useRecoilValue } from "recoil";
import { purchasesQuery } from "../store/purchases";


export default function Video({
  id,
  attributes
}: Article) {
  const purchases = useRecoilValue(purchasesQuery);
  const purchased = (purchases || []).indexOf(id) !== -1;
  const priceText = attributes.htw_video_price === 0 ? "Gratis" :
    purchased ? "Gekocht" : formatPrice(attributes.htw_video_price || 0);

  return (
    <div className={styles.video}>
      <VideoThumbnail
        id={id}
        size="small"
        title={attributes.title}
        category={attributes.category.data}
        thumbnailUrl={attributes.image.data.attributes.formats.thumbnail.url}
        free={attributes.htw_video_price === 0 || (purchases || []).indexOf(id) !== -1} />

      <div className={styles.content}>
        <div className={styles.metadata}>
          <Link href={`/videos/${attributes.category.data.attributes.slug}`}>
            <span>{attributes.category.data.attributes.name}</span>
          </Link>

          <div>
            <Label secondary>
              <CaretFill /> {formatTime(attributes.htw_video_length!)}
            </Label>
            <Label>{priceText}</Label>
          </div>
        </div>

        <h3>{attributes.title}</h3>

        <p>
          {attributes.introduction}
        </p>
      </div>
    </div>
  );
}

