import React from "react";
import Link from "next/link";
import Image from "next/image";

import { className, slugify } from "../utils";

import styles from "../styles/VideoThumbnail.module.scss";
import { Category } from "../api/strapi/category";

interface Props {
  id: number;
  title: string;
  category: Category;
  thumbnailUrl: string;
  size?: "small" | "normal";
  free: boolean;
}

export default function VideoThumbnail({ id, title, category, thumbnailUrl, size = "normal", free = false }: Props) {
  const slug = slugify(title);
  const playClassName = className(styles, "play_button", { small: size === "small" });

  return (
    (<Link href={`/videos/${category.attributes.slug}/${id}/${slug}`} className={styles.video}>
      <figure className={styles.thumbnail}>
        <Image className={size === "small" ? styles['thumbnail-small'] : ""} src={thumbnailUrl} alt="Hans Teeuwen: [videotitel]" width="843" height="513" />
      </figure>
      <div className={playClassName}>
        <div>
          <span>{free ? "Kijk nu" : "Koop en kijk"}</span>
        </div>
      </div>
    </Link>)
  );
}
