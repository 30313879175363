export const SITE_TITLE = "Hans Teeuwen World";
export const SITE_DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN || "";
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || "";
export const KRATOS_API_URL = process.env.NEXT_PUBLIC_KRATOS_API_URL || "";
export const KRATOS_LOGIN_URL = `${KRATOS_API_URL}/self-service/login/browser`;
export const KRATOS_REGISTRATION_URL = `${KRATOS_API_URL}/self-service/registration/browser`;
export const KRATOS_RECOVERY_URL = `${KRATOS_API_URL}/self-service/recovery/browser`;
export const KRATOS_LOGOUT_URL = `${KRATOS_API_URL}/self-service/browser/flows/logout`;
export const KRATOS_SETTINGS_URL = `${KRATOS_API_URL}/self-service/settings/browser`;
export const STRAPI_API_URL = process.env.NEXT_PUBLIC_STRAPI_API_URL || "";
export const PAYMENTS_API_URL = process.env.NEXT_PUBLIC_PAYMENTS_API_URL || "";
export const FEATURED_ARTICLE_ID = !!process.env.NEXT_PUBLIC_FEATURED_ARTICLE_ID ? parseInt(process.env.NEXT_PUBLIC_FEATURED_ARTICLE_ID) : -1;

if (SITE_DOMAIN === "") {
  throw 'NEXT_PUBLIC_SITE_DOMAIN undefined';
}
if (ENVIRONMENT === "") {
  throw 'NEXT_PUBLIC_ENVIRONMENT undefined';
}
if (KRATOS_API_URL === "") {
  throw 'NEXT_PUBLIC_KRATOS_API_URL undefined';
}
if (STRAPI_API_URL === "") {
  throw 'NEXT_PUBLIC_STRAPI_API_URL undefined';
}
if (PAYMENTS_API_URL === "") {
  throw 'NEXT_PUBLIC_PAYMENTS_API_URL undefined';
}
if (FEATURED_ARTICLE_ID === -1) {
  throw 'NEXT_PUBLIC_FEATURED_ARTICLE_ID undefined';
}

export const MESSAGES: { [code: string]: { [key: string]: string } } = {
  "nl-NL": {
    "kratos.message.4000001": "Dit veld is verplicht",
    "kratos.message.4000005": "Het wachtwoord is verplicht en moet minimaal 6 karakters bevatten",
    "kratos.message.4000006": "Het ingevoerde e-mailadres en/of wachtwoord is onjuist",
    "kratos.message.4000007": "Het gekozen e-mailadres is al ingebruik",
    "kratos.message.1060002": "Een e-mail met een reset link is verzonden naar het e-mailadres wat je hebt opgegeven",
    "kratos.message.1060001": "Je kunt nu een nieuw wachtwoord instellen",
    "kratos.message.1050001": "Wachtwoord reset is gelukt, je kunt je account weer gebruiken met het wachtwoord wat je zojuist hebt ingesteld",

    "kratos.login.password_identifier": "E-mailadres",
    "kratos.login.password": "Wachtwoord",
    "kratos.login.method": "Inloggen",
    "kratos.login.identifier": "E-mailadres",
    "kratos.recovery.email": "E-mailadres",
    "kratos.recovery.method": "Wachtwoord vergeten",
    "kratos.register.traits.email": "E-mailadres",
    "kratos.register.password": "Wachtwoord",
    "kratos.register.traits.name.first": "Voornaam",
    "kratos.register.traits.name.last": "Achternaam",
    "kratos.register.method": "Registreren",
    "kratos.settings.password" : "Wachtwoord",
    "kratos.settings.traits.email" : "E-mailadres",
    "kratos.settings.traits.name.first" : "Voornaam",
    "kratos.settings.traits.name.last" : "Achternaam",
    "kratos.settings.method" : "Opslaan"
  }
};
