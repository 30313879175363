import {atom, selector} from "recoil";
import {Session} from "@ory/kratos-client";

interface SessionData {
  data: Session | null;
  authenticated: boolean;
}

const session = atom<SessionData | null>({
  key: "session",
  default: null,
});

export const authenticatedQuery = selector({
  key: "authenticatedQuery",
  get: ({get}) => {
    const sessionValue = get(session)

    if (sessionValue === null) {
      return null
    }

    return sessionValue.authenticated
  }
})

export default session;
