import {atom, selector} from "recoil";
import {authenticatedQuery} from "./session";

const subscription = atom<boolean>({
  key: "subscription",
  default: false
});

export const subscriptionQuery = selector({
  key: "subscriptionQuery",
  get: ({get}): boolean => {
    const authenticated = get(authenticatedQuery);

    if (!authenticated) {
      return false;
    }

    return get(subscription) || false;
  },
});

export default subscription;
