import React, { useCallback, useEffect, useState } from "react";

import { useStrapi } from "../context";
import { Article, DetailedArticle } from "../api/strapi/article";

import styles from "../styles/VideoList.module.scss";
import Video from "./Video";
import Button from "./Button";

interface Props {
  title: string;
  category?: string | undefined,
  articles: Article[] | DetailedArticle[],
}

export const STRAPI_MAX_ITEMS = 20;

export default function VideoList({ title, articles, category }: Props) {
  const strapi = useStrapi();
  const [page, setPage] = useState(1);
  const [paginatedArticles, setPaginatedArticles] = useState<Article[]>([]);

  useEffect(() => {
    setPaginatedArticles([]);
    setPage(1);
  }, [category]);

  async function fetchNextPage() {
    const nextArticles = await strapi.listArticles(page + 1, category);

    setPage(page + 1);
    setPaginatedArticles([...paginatedArticles, ...nextArticles]);
  };

  const allArticles = [...articles, ...paginatedArticles];

  return (<div className={styles.list}>
    <h2 className={styles.heading}>{title}</h2>
    <div className={styles.grid}>
      {allArticles
        .map(article =>
          <Video
            key={article.id}
            {...article}
          />
        )}
    </div>

    {(allArticles.length % STRAPI_MAX_ITEMS) === 0 &&
      <Button secondary onClick={fetchNextPage}>Meer videos</Button>}
  </div>)
}
