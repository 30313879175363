import {MESSAGES} from "./config";

type Item = string | { [key: string]: boolean };

export function className(styles: any, ...names: Item[]): string {
  let classNames: string[] = [];

  names.forEach(item => {
    if (typeof (item) === "string") {
      classNames.push(styles[item]);
    }

    if (typeof (item) === "object") {
      Object.keys(item)
        .filter(key => item[key] === true)
        .forEach(key => {
          classNames.push(styles[key]);
        });
    }
  });

  return classNames.join(" ");
}

// NOTE: This slugify function should be kept in sync with the slugify function
// in the payment-api slugify.go file.This logic should be part of the CMS.
export function slugify(str: string): string {
  str = str.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();

  const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/\s+/g, "-")       // replace whitespace by dashes
    .replace(/-+/g, "-")        // collapse dashes
    .replace(/[^a-z0-9-]/g, "") // remove invalid chars
    .replace(/^-+/, "")         // trim dashes from start of text
    .replace(/-+$/, "");        // trim dashes from end of text

  return str;
}

export function translate(key: string, fallback: string): string {
  const translated = MESSAGES["nl-NL"][key];

  if (translated) {
    return translated;
  }

  console.warn(`missing translation for: ${key}`);

  return fallback;
}
