import {atom, selector} from "recoil";
import {authenticatedQuery} from "./session";

const purchases = atom<number[] | null>({
  key: "purchases",
  default: null
});

export const purchasesQuery = selector({
  key: "purchasesQuery",
  get: ({get}): number[] | null => get(purchases) || null,
});

export default purchases;
