import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'

import { ErrorResponse } from '../models/ErrorResponse';
import { PurchaseRequest } from '../models/PurchaseRequest';
import { PurchaseResponse } from '../models/PurchaseResponse';
import { PurchaseStatusRequest } from '../models/PurchaseStatusRequest';
import { PurchaseStatusResponse } from '../models/PurchaseStatusResponse';
import { PurchasedResponse } from '../models/PurchasedResponse';
import { SubscriptionResponse } from '../models/SubscriptionResponse';
import { ObservableDefaultApi } from './ObservableAPI';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class PromiseDefaultApi {
    private api: ObservableDefaultApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get the videos a user has access to through purchases or a subscription
     */
    public purchasesGet(options?: Configuration): Promise<PurchasedResponse> {
        const result = this.api.purchasesGet(options);
        return result.toPromise();
    }

    /**
     * Create a new purchase
     * @param purchaseRequest 
     */
    public purchasesPost(purchaseRequest: PurchaseRequest, options?: Configuration): Promise<PurchaseResponse> {
        const result = this.api.purchasesPost(purchaseRequest, options);
        return result.toPromise();
    }

    /**
     * Get the status of a purchase
     * @param reference reference of the transaction acquired by creating a new purchase
     */
    public purchasesStatusReferenceGet(reference: string, options?: Configuration): Promise<PurchaseStatusResponse> {
        const result = this.api.purchasesStatusReferenceGet(reference, options);
        return result.toPromise();
    }

    /**
     * Delete the active subscription
     */
    public subscriptionDelete(options?: Configuration): Promise<any> {
        const result = this.api.subscriptionDelete(options);
        return result.toPromise();
    }

    /**
     * Check if the user has an active subscription
     */
    public subscriptionGet(options?: Configuration): Promise<SubscriptionResponse> {
        const result = this.api.subscriptionGet(options);
        return result.toPromise();
    }


}



