import {Configuration, FrontendApi} from "@ory/kratos-client";
import {createContext, useContext} from "react";

import { KRATOS_API_URL, STRAPI_API_URL, PAYMENTS_API_URL} from "./config";
import StrapiClient, {Auth} from "./api/strapi/client";
import {PromiseDefaultApi} from "./api/payments/types/PromiseAPI";
import {createConfiguration, ServerConfiguration} from "./api/payments";

interface ContextData {
  kratos: FrontendApi,
  strapi: StrapiClient,
  payments: PromiseDefaultApi,
}

var globalContextData: ContextData | undefined;

// @ts-ignore
export const Context = createContext<ContextData>();

// implemented as a singleton
export function createContextData(): ContextData {
  if (globalContextData !== undefined) {
    return globalContextData;
  }

  const contextData = {
    strapi: new StrapiClient(STRAPI_API_URL, Auth.fromEnv()),
    payments: new PromiseDefaultApi(createConfiguration({
      baseServer: new ServerConfiguration(PAYMENTS_API_URL, {}),
    })),
    kratos: new FrontendApi(new Configuration({basePath: KRATOS_API_URL})),
  };

  globalContextData = contextData;

  return contextData;
}


/**
 * Hooks for React components
 */
export function useStrapi(): StrapiClient {
  return useContext(Context).strapi;
}

export function useKratos(): FrontendApi {
  return useContext(Context).kratos;
}

export function usePayments(): PromiseDefaultApi {
  return useContext(Context).payments;
}


/**
 * Helpers for server sided rendering
 */
export function getStrapiClient(): StrapiClient {
  return createContextData().strapi;
}

export function getKratosClient(): FrontendApi {
  return createContextData().kratos;
}

export function getPaymentsClient(): PromiseDefaultApi {
  return createContextData().payments;
}
