/**
 * payments-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* A JSON object with a reference and a checkout URL
*/
export class PurchaseResponse {
    'reference'?: string;
    'checkoutURL'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "reference",
            "baseName": "reference",
            "type": "string",
            "format": ""
        },
        {
            "name": "checkoutURL",
            "baseName": "checkoutURL",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PurchaseResponse.attributeTypeMap;
    }
    
    public constructor() {
    }
}

