import { Article, DetailedArticle } from "./article";
import { Category } from "./category";
import { STRAPI_MAX_ITEMS } from "../../components/VideoList";

export class Auth {
  public username: string;
  public password: string;

  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }

  static fromEnv(): Auth | null {
    if (process.env.NEXT_PUBLIC_LUCY_API_USERNAME && process.env.NEXT_PUBLIC_LUCY_API_PASSWORD) {
      return new Auth(process.env.NEXT_PUBLIC_LUCY_API_USERNAME, process.env.NEXT_PUBLIC_LUCY_API_PASSWORD);
    }

    return null;
  }
}

export default class Client {
  private readonly url: string;

  constructor(url: string, auth?: Auth | null) {
    this.url = url;
  }

  async request<T>(path: string): Promise<T> {
    let headers: { [key: string]: string } = {};

    const response = await fetch(`${this.url}${path}`, {
      headers,
    });
    return await response.json();
  }

  async listArticles(page?: number, category?: string): Promise<Article[]> {
    const result = await this.request<{
      data: Article[]
    }>(`/articles/?populate[image][populate][0]=thumbnail${category ? "&filters[category][slug][$eqi]=" + category : ""}&pagination[page]=${page || 1}&pagination[pageSize]=${STRAPI_MAX_ITEMS}&populate[category]=*&populate[article_blocks]=*&sort[0]=publish_start_datetime:desc`);

    return result.data;
  }

  async getArticles(ids: number[]): Promise<DetailedArticle[]> {
    return await Promise.all<DetailedArticle>(ids.map(this.getArticle.bind(this)));
  }

  async getArticle(id: number): Promise<DetailedArticle> {
    const result = await this.request<{
      data: DetailedArticle
    }>(`/articles/${id}?populate[image][populate][0]=thumbnail&populate[category]=*&populate[article_blocks]=*`);

    return result.data;
  }

  async listCategories(): Promise<Category[]> {
    const result = await this.request<{
      data: Category[]
    }>("/categories/");

    return result.data;
  }
}
