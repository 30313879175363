import React, {useEffect} from "react";
import {RecoilRoot, useRecoilState} from "recoil";
import PlausibleProvider from "next-plausible";
import {datadogRum} from '@datadog/browser-rum';


import {ENVIRONMENT, SITE_DOMAIN} from "../config";
import {Context, createContextData, useKratos, usePayments} from "../context";
import "../styles/globals.scss";
import session from "../store/session";
import purchases from "../store/purchases";
import subscription from "../store/subscription";

interface Props {
  Component: React.ElementType,
  pageProps: { [key: string]: any }
}

export default function App(props: Props) {
  useEffect(() => {
    datadogRum.init({
      applicationId: '9a65f18e-aa91-41f5-bbfe-5e582354f6e7',
      clientToken: 'pub3361df99f2681db929b00b3dcdb613bd',
      site: 'datadoghq.eu',
      service: 'magenta_htw_frontend',
      env: ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true
    });
  }, []);

  return (<Context.Provider value={createContextData()}>
    <RecoilRoot>
      <PlausibleProvider domain={SITE_DOMAIN}>
        <RootComponent {...props} />
      </PlausibleProvider>
    </RecoilRoot>
  </Context.Provider>);
}

function RootComponent({Component, pageProps}: Props) {
  const kratos = useKratos();
  const payments = usePayments();
  const [sessionValue, setSession] = useRecoilState(session);
  const [_, setSubscriptionValue] = useRecoilState(subscription);
  const [purchasesValue, setPurchasesValue] = useRecoilState(purchases);

  async function fetchPurchases() {
    const response = await payments.purchasesGet();

    setPurchasesValue(response?.articleIDs || []);
    setSubscriptionValue(response?.hasSubscription || false);
  }

  async function fetchSession() {
    try {
      const response = await kratos.toSession({}, {
        withCredentials: true,
      });

      setSession({
        authenticated: response.data.active || false,
        data: response.data,
      });
    } catch (e: any) {
      if (e.response && e.response.status === 401) {
        setSession({
          authenticated: false,
          data: null
        });

        return null;
      }

      throw e;
    }
  }

  useEffect(() => {
    if (sessionValue === null) {
      fetchSession();
    }

    if (sessionValue?.authenticated && purchasesValue === null) {
      fetchPurchases();
    }
  }, [sessionValue]);

  return <Component {...pageProps} />;
}
