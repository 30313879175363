// Format the video-length (as seconds)
export function formatTime(seconds: number): string {
  const fmt = (input: number) => input < 10 ?
    `0${input}` :
    `${input}`;

  let hours = 0;
  let minutes = 0;

  while (seconds >= 3600) {
    hours++;
    seconds -= 3600;
  }

  while (seconds >= 60) {
    minutes++;
    seconds -= 60;
  }

  if (hours === 0) {
    return `${fmt(minutes)}:${fmt(seconds)}`;
  }

  return `${hours}:${fmt(minutes)}:${fmt(seconds)}`;
}


// Format the price (as EUR cents)
export function formatPrice(price: number): string {
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR"
  });

  return formatter.format(price / 100);
}
