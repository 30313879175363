import React from "react";

import styles from "../styles/Label.module.scss";
import {className} from "../utils";

interface Props {
  secondary?: boolean;
  children: React.ReactNode;
}

export default function Label({children, secondary=false}: Props) {
  const labelClassName = className(styles, "label", {secondary});

  return (<div className={labelClassName}>
    {children}
  </div>);
}
